import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image'
//import { Gallery } from "gatsby-theme-gallery";
//import WorksSection from '../components/WorksSection';
//import { Gallery } from "gatsby-gallery-auto";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import photos from "../data/photos";
import slides from "../data/slides";
import { SEO } from "../components/seo"


//const GalleryPage = ({ data }) => {
const GalleryPage = () => {

  //const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  // `images` is an array of objects with `thumb` and `full`
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(-1);


  return (
    <Layout pageTitle="Gallery">
      {/* <Lightroom images={images} settings={settings} /> */}
    {/* <Gallery images={images} /> */}
    {/* <ImageGallery items={images} />; */}
 {/*      <LightGallery
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
      >
        <a href="/src/media/images/bathroom.jpeg">
          <StaticImage src="../media/images/bathroom.jpeg" />
        </a>
        <a href="/src/media/images/bathroom.jpeg">
          <StaticImage src="../media/images/bathroom.jpeg" />
        </a>
      </LightGallery> */}
      {/* <button type="button" onClick={() => setOpen(true)}>
        Open Lightbox
      </button> */}
      <PhotoAlbum
        layout="rows"
        photos={photos}
        targetRowHeight={150}
        onClick={(event, photo, index) => setIndex(index)}
        componentsProps={{ imageProps: { loading: "lazy" } }}
      />
     {/*  <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={[
          {
            src: "/media/images/bathroom.jpeg",
            alt: "image 1",
            width: 3840,
            height: 2560,
            srcSet: [
              { src: "/media/images/bathroom.jpeg", width: 3840, height: 2560 },
            ]
          },
          
            <StaticImage
              alt="Chris Kitchen"
              src="../media/images/chriskitchen.jpeg"
            />
          
          // ...
        ]}
      />
       <PhotoAlbum
        layout="rows"
        photos={photos}
        targetRowHeight={150}
        onClick={(event, photo, index) => setIndex(index)}
        componentsProps={{ imageProps: { loading: "lazy" } }}
      /> */}

      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
      />

    </Layout>
  )
}



/* export const pageQuery = graphql`
  query ImagesForGallery {
    allFile(filter: {relativeDirectory: {eq: "images"}}){
      edges {
        node {
          childImageSharp {
            thumbnail: gatsbyImageData(
              width: 200
              height: 200
              placeholder: BLURRED
            )
            original: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
` */


export const Head = () => (
  <SEO title="Gallery" />
)

export default GalleryPage